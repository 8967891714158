<template>
  <b-modal
    id="modal-action"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="mb-4 d-flex justify-content-between">
      <h5 class="font-weight-bold">{{ text }} Confirmation</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <div class="mb-3 mx-5 text-center px-4 py-2 font-weight-bold">
      <p class="mx-3">Are you sure want to {{ text }} project {{ projectName }} ?</p>
    </div>
    <validation-observer
      ref="form"
      slim
    >
      <validation-provider
        name="Remark"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Remark *"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-model="form.remark"
            placeholder="Enter remark"
            rows="4"
            max-rows="6"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-warning mt-2"
        variant="outline-warning"
        block
      >
        No
      </b-button>
      <b-button
        class="text-white ml-3"
        variant="warning"
        block
        @click="submit"
      >
        Yes
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => ''
    },
    projectName: {
      type: String,
      default: () => ''
    }
  },

  data: () => ({
    form: {
      remark: null,
      spkNo: null,
      spkDate: null,
      spkUploads: null
    }
  }),

  methods: {
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }

      this.$emit('submit', this.form)
      this.$bvModal.hide('modal-action')
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-action')
    }
  }
}
</script>
