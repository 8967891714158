<template>
  <div class="d-flex view-project">
    <div class="container-view left p-4 mr-4">
      <h5 class="mb-4 font-weight-bold">Project Detail</h5>
      <b-row>
        <b-col>
          <div class="text-muted">Project Name</div>
          <div class="font-weight-bold mt-2">{{ detail.name }}</div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Project Code</div>
              <div class="font-weight-bold mt-2">{{ detail.code }}</div>
            </div>
            <div class="">
              <div class="text-muted">KEY</div>
              <div class="font-weight-bold mt-2">{{ detail.key }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Type of Coorperation</div>
          <div class="font-weight-bold mt-2">{{ detail.typeOfCoorporation }}</div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Required Resource</div>
              <div>
                <b-badge
                  v-for="(item, p) in detail.projectResources"
                  :key="p"
                  class="px-3 py-2 mr-2 mt-2"
                >
                  {{ item.qty }} {{ item.developmentRole.code }}
                </b-badge>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Technology</div>
          <div>
            <b-badge
              v-for="(item, p) in detail.technology"
              :key="p"
              class="px-3 py-2 mr-2 mt-2"
            >
              {{ item }}
            </b-badge>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Status Project</div>
              <div>
                <b-badge class="status px-3 py-2 mt-2">
                  {{ detail.status }}
                </b-badge>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Init State</div>
          <div class="font-weight-bold mb-4 mt-2">{{ detail.initState }}</div>

          <div class="text-muted">Short Descriptions</div>
          <div class="mt-2">{{ detail.shortDescription }}</div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Descriptions</div>
              <div class="mt-2">{{ detail.description }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container-view right p-4">
      <h5 class="font-weight-bold mb-3">Client Name</h5>
      <p>{{ detail.client ? detail.client.name : '-' }}</p>
      <h5 class="font-weight-bold mt-3 mb-3">PIC Project</h5>
      <div class="mb-3">
        <div>PIC Name</div>
        <div class="font-weight-bold">{{ detail.client? detail.client.picName : '-' }}</div>
      </div>
      <div class="mb-3">
        <div>PIC Phone Number</div>
        <div class="font-weight-bold">{{ detail.client? detail.client.picPhone : '-' }}</div>
      </div>
      <div>
        <div>Email PIC</div>
        <div class="font-weight-bold">{{ detail.client? detail.client.picEmail : '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  computed: {},

  data: () => ({
    detail: {
      name: null,
      code: null,
      key: null,
      typeOfCoorporation: null,
      projectResources: [
        {
          qty: null,
          level: null,
          developmentRole: {
            name: null
          }
        }
      ],
      technology: [],
      status: null,
      initState: null,
      shortDescription: null,
      description: null,
      client: {
        name: null,
        picEmail: null,
        picName: null,
        picPhone: null
      }
    }
  }),

  created() {
    this.fetchGetProjectDetail()
  },

  methods: {
    async fetchGetProjectDetail() {
      const { data } = await api.projectDetail.get(this.$route.query.id)

      this.detail = data.data
    }
  }
}
</script>

<style lang="scss" scoped>
.view-project {
  background: #F5F6F9;
}

.container-view {
  background: #fff;
  display: flex;
  flex-direction: column;

  &.left {
    width: 64vw;
  }

  &.right {
    width: 25vw;
  }
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}
</style>
