var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-deal","hide-header":"","hide-footer":"","centered":""},on:{"hidden":_vm.resetModal}},[_c('div',{staticClass:"mb-2 d-flex justify-content-between"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Deal Confirmation")]),_c('b-link',{staticClass:"text-muted",on:{"click":_vm.cancel}},[_c('fa-icon',{attrs:{"icon":"times","size":"lg"}})],1)],1),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('validation-provider',{attrs:{"name":"Remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remark *","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter remark","rows":"4","max-rows":"6"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)]}}])}),_c('h6',[_vm._v("Surat Perintah Kerja")]),_c('validation-provider',{attrs:{"name":"No. SPK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"No. SPK","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter SPK Number"},model:{value:(_vm.form.spkNo),callback:function ($$v) {_vm.$set(_vm.form, "spkNo", $$v)},expression:"form.spkNo"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal","invalid-feedback":errors[0]}},[_c('b-form-datepicker',{class:{ 'is-invalid': errors.length },model:{value:(_vm.form.spkDate),callback:function ($$v) {_vm.$set(_vm.form, "spkDate", $$v)},expression:"form.spkDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"File SPK","rules":"required|size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File SPK","invalid-feedback":errors[0]}},[_c('b-form-file',{class:{ 'is-invalid': errors.length },attrs:{"accept":".pdf","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onFileChange},model:{value:(_vm.form.spkUploads),callback:function ($$v) {_vm.$set(_vm.form, "spkUploads", $$v)},expression:"form.spkUploads"}}),_c('div',{staticClass:"text-muted"},[_vm._v("Max Size : 10 MB")])],1)]}}])})],1),_c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"warning","block":""},on:{"click":_vm.submit}},[_vm._v(" Done ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }