<template>
  <div class="d-flex view-project">
    <div class="container-view left p-4 mr-4">
      <h5 class="mb-4 font-weight-bold">Development Info</h5>
      <b-row>
        <b-col>
          <div class="text-muted">Development Method</div>
          <div class="font-weight-bold">{{ detail.developmentMethod }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Quantity</div>
          <div class="font-weight-bold">{{ detail.quantity }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Day of Sprint</div>
          <div class="font-weight-bold">{{ detail.daysOfSprint }}</div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Kick off Date</div>
              <div class="font-weight-bold">{{ detail.kickoffDate ? moment(detail.kickoffDate).format('DD MMM YYYY') : '' }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Development Period Start</div>
          <div class="font-weight-bold mb-4">{{ detail.developmentStart ? moment(detail.developmentStart).format('DD MMM YYYY') : '' }}</div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Development Period End</div>
              <div class="font-weight-bold mb-4">{{ detail.developmentEnd ? moment(detail.developmentEnd).format('DD MMM YYYY') : '' }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
       <b-row>
        <b-col>
          <div class="text-muted">Holiday</div>
          <div v-for="(item, p) in detail.holidays" :key="p">
                        <b-badge
                        class="px-3 py-1">
                        {{ item.holidayDate ? moment(item.holidayDate).format('DD MMM YYYY') : '' }}
                        </b-badge>
                        </div>
        </b-col>
      </b-row>
      <div class="mt-4">
      <div class="d-flex justify-content-between">
      <h5 class="mb-4 font-weight-bold">
    </h5>
          <b-button
          class="reset"
          variant="outline-warning"
          @click="openModal()"
        >
          View Sprint List
        </b-button>
    </div>
    </div>
    </div>
    <div class="container-view right p-4">
      <h5 class="font-weight-bold mb-3">Maintenance</h5>
      <b-row>
        <b-col>
          <div class="text-muted">Long Maintenance</div>
          <div class="font-weight-bold mb-4">{{ detail.maintenanceLength }} {{ detail.developmentUnit }}</div>
        </b-col>
       </b-row>
       <b-row>
        <b-col>
          <div class="text-muted">Maintenance Period Start</div>
          <div class="font-weight-bold mb-4">{{ detail.maintenanceStart ? moment(detail.maintenanceStart).format('DD MMM YYYY') : '' }}</div>
        </b-col>
        <b-col>
          <div class="text-muted">Maintenance Period Start</div>
          <div class="font-weight-bold mb-4">{{ detail.maintenanceEnd ? moment(detail.maintenanceEnd).format('DD MMM YYYY') : '' }}</div>
        </b-col>
        </b-row>
    </div>
    <modal-sprint :item="sprints" />
  </div>
</template>

<script>
import api from '@/api'
import ModalSprint from './ModalSprint.vue'

export default {
  components: {
    ModalSprint
  },

  computed: {},

  data: () => ({
    sprints: [],
    detail: {
      name: null,
      code: null,
      key: null,
      typeOfCoorporation: null,
      projectResources: [
        {
          qty: null,
          level: null,
          developmentRole: {
            name: null
          }
        }
      ],
      technology: [],
      status: null,
      initState: null,
      shortDescription: null,
      description: null,
      client: {
        name: null,
        picEmail: null,
        picName: null,
        picPhone: null
      }
    }
  }),

  created() {
    this.fetchGetDevelopmentInfo()
  },

  methods: {
    openModal() {
      this.fetchSprintList()
      this.$bvModal.show('modal-sprint')
    },
    async fetchGetDevelopmentInfo() {
      const { data } = await api.developmentInfo.get(this.$route.query.id)
      this.detail = data
    },
    async fetchSprintList() {
      const data = await api.developmentInfo.getSprint(this.$route.query.id)
      this.sprints = data.data
      this.sprints.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-project {
  background: #F5F6F9;
}

.container-view {
  background: #fff;
  display: flex;
  flex-direction: column;

  &.left {
    width: 49vw;
  }

  &.right {
    width: 49vw;
  }
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}
</style>
