<template>
  <b-modal
    id="modal-history"
    size="lg"
    hide-footer
    centered
  >
  <h5 class="font-weight-bold">
        History Proposal
    </h5>
     <div
            v-if="!item.length"
            class="text-center"
          >
            <span>No Data</span>
          </div>
    <b-table
    v-if="item.length"
      class="mt-4"
      striped
      hover
      :items="item"
      :fields="fields"
    >
      <template #cell(sentDate)="{ item }">
        {{ item.sentDate ? moment(item.sentDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(projectAmount)="{ item }">
        {{ formatMoney(item.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(fileName)="{ item }">
        <div class="data">
          <a href="" v-text="item.fileName" v-on:click.stop.prevent="download(item)" />
        </div>
      </template>
      <template #cell(action)="{ item }">
        <div class="data">
          <img src="@/assets/fi_download.png" @click="download(item)" />
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { formatMoney } from 'accounting-js'
import api from '@/api'

export default {
  props: {
    item: {
      type: Array,
      default: () => ''
    }
  },

  methods: {
    formatMoney,
    download(item) {
      this.fetchDownload(item.fileName)
    },
    async fetchDownload(value) {
      await api.proposal.download(value).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = value
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    }
  },

  data: () => ({
    fields: [
      { key: 'proposalNo', label: 'Proposal No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'projectAmount', label: 'Project Amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'sentDate', label: 'Send Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remark', label: 'Change Remark', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'fileName', label: 'File Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ]
  })
}
</script>
