var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-project"},[_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-4"},[_c('div',[_vm._v(" Project Amount ")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")])]),_vm._m(1)])]),_c('b-table',{staticClass:"mt-2",attrs:{"striped":"","hover":"","items":_vm.dataTerm,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(invoiceDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoiceDate ? _vm.moment(item.invoiceDate).format('DD MMM YYYY') : '')+" ")]}},{key:"cell(percentage)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentage)+"% ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(invoiceNote)",fn:function(ref){
var item = ref.item;
return [(item.invoiceNote)?_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.openNote(item.invoiceNote)}}},[_c('fa-icon',{attrs:{"icon":"clipboard-list"}})],1):_vm._e()]}},{key:"cell(reminderDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reminderDate? _vm.moment(item.reminderDate).format('DD MMM YYYY') : '')+" ")]}},{key:"cell(invoices)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoices ? _vm.invoices : '')+" ")]}}])}),_c('modal-additional-note',{attrs:{"item":_vm.note}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('h5',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Payment Term ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._v(" Due Date ")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" 5 Days ")])])}]

export { render, staticRenderFns }