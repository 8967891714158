<template>
  <div class="d-flex view-project">
    <div class="container-view left p-4 mr-4">
      <h5 class="mb-4 font-weight-bold">Summary</h5>
      <b-row>
        <b-col>
          <div class="text-muted">Project Cost</div>
          <div class="font-weight-bold mt-2" :style="{ color : '#2F80ED' }">{{ formatMoney(detail.projectCost, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="font-weight-bold mb-4 mt-2">Labour {{ formatMoney(detail.labour, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        <div class="font-weight-bold mb-4 mt-2">Expenses {{ formatMoney(detail.expenses, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <div class="text-muted">Uninvoice Amount</div>
          <div class="font-weight-bold mb-4 mt-2" :style="{ color : '#F7AC26' }">{{ formatMoney(detail.uninvoicedAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
       <b-row>
        <b-col>
          <div class="font-weight-bold mb-4 mt-2">Project Amount {{ formatMoney(detail.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
          <div class="font-weight-bold mb-4 mt-2">Unpaid Amount {{ formatMoney(detail.unpaidAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
          <div class="font-weight-bold mb-4 mt-2" :style="{ color : '#DB1111' }">Overdue Amount {{ formatMoney(detail.overdueAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
          <div class="font-weight-bold mb-4 mt-2">Paid Amount {{ formatMoney(detail.paidAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <div class="text-muted">Profit Estimation</div>
          <div class="font-weight-bold mb-4 mt-2" :style="{ color : '#4CAF50' }">{{ formatMoney(detail.profitEstimation, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
    </div>
    <div class="container-view right p-4">
      <h5 class="font-weight-bold mb-3">Expenses</h5>
      <div align="right">
        <b-button
        class="next shadow-lg"
        variant="warning" @click="openModal(null, false)">
        Add</b-button>
      </div>
      <b-table
      class="mt-2"
      striped
      hover
      empty-text="No data"
      ref="expenseTable"
      :items="fetchExpenses"
      :fields="fields"
      show-empty
    >

      <template #cell(no)="{ item, index }">
      {{ index + 1 }}
      </template>

      <template #cell(transactionDate)="{ item }">
          {{ item.transactionDate ? moment(item.transactionDate).format('DD MMM YYYY') : '-' }}
      </template>

      <template #cell(billAmount)="{ item }">
        {{ formatMoney(item.billAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>

      <template #cell(action)="{ item }">
        <div class="data">
          <b-link
            class="text-secondary mx-2"
            @click="openModal(item, true)"
          >
            <fa-icon icon="edit" />
          </b-link>
          <b-link
            class="text-secondary mx-2"
            @click="fetchDetailExpense(item)"
          >
            <fa-icon icon="eye"
                />
          </b-link>
          <b-link
            class="text-secondary mx-2"
            @click="openModalDelete(item)"
          >
            <fa-icon icon="trash-alt"
                />
          </b-link>
        </div>
      </template>
      </b-table>
      <div class="d-flex justify-content-end align-items-center">
        <span class="show-entries mr-auto ml-4">
          {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
        </span >
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          pills
          hide-goto-end-buttons
          @input="fetchExpenses"
        />
      </div>
    </div>
    <modal-expense
    :addExpenseForm="addExpenseForm"
    :isEdit="isEdit"
    :isActivity="isActivity"
    :activityOption="activityOption"
    @set="saveExpenses"/>
    <modal-confirmation-delete
    @click="deleteExpense"
    :id="id"/>
    <modal-view-expense :viewExpense="viewExpense"/>
  </div>
</template>

<script>
import api from '@/api'
import moment from 'moment'
import { formatMoney } from 'accounting-js'
import ModalExpense from './ModalExpense.vue'
import ModalConfirmationDelete from './ModalConfirmationDelete.vue'
import ModalViewExpense from './ModalViewExpense'

export default {
  components: {
    ModalExpense,
    ModalConfirmationDelete,
    ModalViewExpense
  },
  computed: {},

  data: () => ({
    isEdit: false,
    isActivity: false,
    id: '',
    addExpenseForm: {},
    expenseForm: {
    },
    projectId: '',
    activityOption: [],
    typeOption: [],
    viewExpense: {
      activityId: null,
      billAmount: 0,
      budgetActivity: {
        budgetType: {
          id: null,
          typeName: null
        },
        budgetTypeId: null,
        id: null,
        isShowInProjectExpense: false,
        name: null
      },
      budgetTypeId: null,
      createdDate: null,
      description: null,
      expenseBills: [
        {
          filename: null,
          originalFilename: null
        }
      ],
      file: {
        name: null
      },
      id: null,
      projectId: null,
      transactionDate: null
    },
    items: [],
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    detail: {
      projectCost: 0,
      labour: 0,
      expenses: 0,
      uninvoicedAmount: 0,
      projectAmount: 0,
      unpaidAmount: 0,
      overdueAmount: 0,
      paidAmount: 0,
      propfitEstimation: 0
    },
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'budgetActivity.name', label: 'Activity', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'transactionDate', label: 'Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'description', label: 'Description', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'billAmount', label: 'Amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ]
  }),

  created() {
    this.fetchGetProjectCost()
  },

  methods: {
    formatMoney,
    async fetchGetProjectCost() {
      const { data } = await api.projectCost.getSummary(this.$route.query.id)
      this.detail = data.data
    },
    async saveExpenses(addExpenseForm) {
      try {
        const formData = new FormData()
        formData.append('projectId', this.$route.query.id)
        formData.append('activityId', addExpenseForm.activity_id)
        formData.append('description', addExpenseForm.description)
        formData.append('billAmount', addExpenseForm.billAmount)
        formData.append('bills', addExpenseForm.file)
        formData.append('billId', addExpenseForm.billId)
        formData.append('transactionDate', moment(addExpenseForm.transactionDate).format('YYYY-MM-DD'))
        if (!this.isEdit) {
          await api.projectCost.saveExpense(formData)
        } else {
          await api.projectCost.editExpense(addExpenseForm.id, formData)
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save Expense', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$refs.expenseTable.refresh()
        await this.fetchGetProjectCost()
        setTimeout(() => {
          this.$bvModal.hide('modal-expense')
        }, 2000)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    fetchDetailExpense(item) {
      this.viewExpense = item
      this.openModalView()
    },
    async deleteExpense() {
      try {
        this.isBusy = true
        await api.projectCost.deleteExpense(this.id)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success delete Expenses', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$refs.expenseTable.refresh()
        await this.fetchGetProjectCost()
        setTimeout(() => {
          this.$bvModal.hide('modal-confirmation-delete')
        }, 2000)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async fetchTypeList() {
      const { data } = await api.budget.type(
        {
          Filters: 'typeName@=*',
          Sorts: 'typeName',
          Page: 1,
          PageSize: 10
        }
      )
      this.typeOption = data.data
    },
    async openModal(item, isEdit) {
      this.isEdit = isEdit

      if (isEdit) {
        const { data } = await api.projectCost.detail(item.id)
        this.addExpenseForm = data.data ? { ...data.data } : {}
        this.addExpenseForm.budgetTypeId = data.data ? data.data.budgetActivity.budgetType.id : null
        let activity = {}
        await this.fetchTypeList('', false)
        this.activityOption = this.typeOption.find(element => element.id === item.budgetActivity.budgetType.id).budgetActivities || []
        this.activityOption.map(v => {
          if (this.addExpenseForm.activityId === v.id) {
            activity = v
          }
        })
        this.addExpenseForm.activityId = activity
      } else {
        this.addExpenseForm = {}
        this.addExpenseForm.budgetTypeId = null
        this.activityOption = []
      }

      this.isActivity = isEdit
      this.$bvModal.show('modal-expense')
    },
    openModalDelete(item) {
      this.id = item.id
      this.$bvModal.show('modal-confirmation-delete')
    },
    openModalView() {
      this.$bvModal.show('modal-view-expense')
    },
    async fetchExpenses() {
      this.filters = 'projectId==' + this.$route.query.id
      const { data } = await api.projectCost.listExpense({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.filters,
        sorts: ''
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    }
  }
}
</script>

<style lang="scss" scoped>
.view-project {
  background: #F5F6F9;
}

.container-view {
  background: #fff;
  display: flex;
  flex-direction: column;

  &.left {
    width: 25vw;
  }

  &.right {
    width: 64vw;
  }
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}
</style>
