var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-history","size":"lg","hide-footer":"","centered":""}},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" History Proposal ")]),(!_vm.item.length)?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v("No Data")])]):_vm._e(),(_vm.item.length)?_c('b-table',{staticClass:"mt-4",attrs:{"striped":"","hover":"","items":_vm.item,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(sentDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sentDate ? _vm.moment(item.sentDate).format('DD MMM YYYY') : '')+" ")]}},{key:"cell(projectAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(fileName)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[_c('a',{attrs:{"href":""},domProps:{"textContent":_vm._s(item.fileName)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.download(item)}}})])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[_c('img',{attrs:{"src":require("@/assets/fi_download.png")},on:{"click":function($event){return _vm.download(item)}}})])]}}],null,false,3097927393)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }