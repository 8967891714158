<template>
  <b-modal
    ref="modal-view-expense"
    id="modal-view-expense"
    title="View Expense"
    centered
    hide-footer
    hide-header
  >
    <div class="p-3">
      <div class="mb-3">
          <b-row>
            <b-col>
                <b-form-group label="Type" >
                  <label class="font-weight-bold"> {{ viewExpense.budgetActivity.budgetType.typeName }} </label>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Activity" >
                <label class="font-weight-bold">{{ viewExpense.budgetActivity.name }}</label>
              </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Date" >
              <div>
              {{ moment(viewExpense.transactionDate).format('DD MMM YYYY') }}
              </div>
                </b-form-group>
                </b-col>
                      <b-col>
                        <div class="text-muted">Amount</div>
                        <div>
                        {{ formatMoney(viewExpense.billAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
              </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group label="Description" >
                        <label class="font-weight-bold">{{ viewExpense.description }}</label>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        v-if="viewExpense.expenseBills.length > 0"
                      >
                        <b-form-group
                          label="Uploaded File"
                        >
                          <div
                            v-for="item in viewExpense.expenseBills"
                            :key="item.id"
                          >
                            <a href="" v-text="item.originalFilename" v-on:click.stop.prevent="download(item)" />
                          </div>
                        </b-form-group>
                        </b-col>
                    </b-row>
          </div>
    </div>
  </b-modal>
</template>

<script>
import { formatMoney } from 'accounting-js'
import api from '@/api'

export default {
  props: {
    viewExpense: {
      type: Object,
      default: () => ({
        budgetActivity: {
          budgetType: {
            id: null,
            typeName: null
          }
        }
      })
    }
  },
  data: () => ({
  }),
  methods: {
    formatMoney,
    download(item) {
      this.fetchDownload(item)
    },
    async fetchDownload(item) {
      await api.projectCost.download(item.id).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = item.originalFilename
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>
