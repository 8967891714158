<template>
  <div class="view-project">
    <div class="mb-4">
      <div class="d-flex justify-content-between">
        <h5 class="mb-4 font-weight-bold">
          Payment Term
        </h5>
      </div>
      <div class="d-flex">
        <div class="mr-4">
          <div>
            Project Amount
          </div>
          <div class="font-weight-bold">
            {{ formatMoney(projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
          </div>
        </div>
        <div>
          <div>
            Due Date
          </div>
          <div class="font-weight-bold">
            5 Days
          </div>
        </div>
      </div>
    </div>
    <b-table
      class="mt-2"
      striped
      hover
      :items="dataTerm"
      :fields="fields"
    >
      <template #cell(invoiceDate)="{ item }">
        {{ item.invoiceDate ? moment(item.invoiceDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(percentage)="{ item }">
        {{ item.percentage }}%
      </template>
      <template #cell(amount)="{ item }">
        {{ formatMoney(item.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(invoiceNote)="{ item }">
        <b-link
          v-if="item.invoiceNote"
          class="text-secondary mx-2"
          @click="openNote(item.invoiceNote)"
        >
          <fa-icon icon="clipboard-list" />
        </b-link>
      </template>
      <template #cell(reminderDate)="{ item }">
        {{ item.reminderDate? moment(item.reminderDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(invoices)="{ item }">
        {{ item.invoices ? invoices : '' }}
      </template>
    </b-table>
    <modal-additional-note :item="note" />
  </div>
</template>

<script>
import api from '@/api'
import ModalAdditionalNote from '../../newProject/paymentTerm/ModalAdditionalNote.vue'
import { formatMoney } from 'accounting-js'

export default {
  components: {
    ModalAdditionalNote
  },

  computed: {
    dataTerm() {
      return this.termForm.filter(v => v.status !== 'delete')
    }
  },

  data: () => ({
    isBusy: false,
    projectAmount: 0,
    paymentDueDate: null,
    paymentDueUnit: 'Day(s)',
    dateTypeOptions: [
      'Day(s)',
      'Month(s)',
      'Year(s)'
    ],
    fields: [
      { key: 'title', label: 'Term Title', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceDate', label: 'Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'percentage', label: 'Percent', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'amount', label: 'amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remarks', label: 'Remarks', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceNote', label: 'Additional Note', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoices', label: 'Invoice', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'reminderDate', label: 'Reminder Date', tdClass: 'text-center', thClass: 'text-center' }
    ],
    termForm: [],
    isEdit: false,
    note: null,
    isUpdate: null
  }),

  created() {
    if (this.$route.query.id) {
      this.fetchGetPaymentTerm()
    } else {
      this.projectAmount = localStorage.getItem('amount')
    }
  },

  methods: {
    formatMoney,
    async fetchGetPaymentTerm() {
      this.isUpdate = true

      const { data } = await api.paymentTerm.get(this.$route.query.id)

      this.projectAmount = data.data.projectAmount
      this.paymentDueDate = data.data.overdueLength
      this.paymentDueUnit = data.data.overdueUnit

      this.termForm = data.data.paymentTerms

      this.termForm.reverse()

      this.termForm.map(v => {
        delete v.createdBy
        delete v.createdDate
        delete v.deletedBy
        delete v.deletedDate
        delete v.invoiceId
        delete v.projectDetail
        delete v.projectId
        delete v.updatedBy
        delete v.updatedDate

        if (v.taxes.length) {
          for (const [i, value] of v.taxes.entries()) {
            if (value.taxId && this.isUpdate) v.taxes[i] = value.taxId
          }
        }
      })
    },
    openNote(item) {
      this.note = item
      this.$bvModal.show('modal-additional-note')
    }
  }
}
</script>

<style lang="scss" scoped>
.view-project {
  border-radius: 12px;
}

.button-project {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
