<template>
  <b-modal
    id="modal-deal"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="mb-2 d-flex justify-content-between">
      <h5 class="font-weight-bold">Deal Confirmation</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <validation-observer
      ref="form"
      slim
    >
      <validation-provider
        name="Remark"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Remark *"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-model="form.remark"
            placeholder="Enter remark"
            rows="4"
            max-rows="6"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <h6>Surat Perintah Kerja</h6>
      <validation-provider
        name="No. SPK"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="No. SPK"
          :invalid-feedback="errors[0]"
        >
          <b-form-input
            v-model="form.spkNo"
            placeholder="Enter SPK Number"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Tanggal"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Tanggal"
          :invalid-feedback="errors[0]"
        >
          <b-form-datepicker
            v-model="form.spkDate"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="File SPK"
        rules="required|size:10240"
        v-slot="{ errors }"
      >
        <b-form-group
          label="File SPK"
          :invalid-feedback="errors[0]"
        >
          <b-form-file
            v-model="form.spkUploads"
            accept=".pdf"
            @change="onFileChange"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :class="{ 'is-invalid': errors.length }"
          />
          <div class="text-muted">Max Size : 10 MB</div>
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-white"
        variant="warning"
        block
        @click="submit"
      >
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data: () => ({
    form: {
      remark: null,
      spkNo: null,
      spkDate: null,
      spkUploads: null
    }
  }),

  methods: {
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }

      this.$emit('submit', this.form)
      this.$bvModal.hide('modal-deal')
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.form.spkUploads = file
      var size = parseFloat(this.form.spkUploads.size / (1024 * 1024)).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.form.spkUploads = null
      } else {
        var check = file.name.split('.').pop()
        const arrDocType = ['pdf']
        if (arrDocType.includes(check.toLowerCase())) {
          this.isProposalNo = false
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        } else {
          this.form.spkUploads = null
          e = ''
          this.$bvToast.toast('Only pdf files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-deal')
    }
  }
}
</script>
