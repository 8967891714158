<template>
  <div class="card-project">
    <div>
      <h5 class="mb-4 font-weight-bold">
        Development Team
      </h5>
      <b-row>
        <b-col>
          <b-form-group
            label="Product Owner"
          >
          <b-form-input
            v-model="productOwner"
            placeholder="Enter Product Owner"
            disabled
          />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Scrum Master"
          >
            <custom-select
              v-model="scrumMaster"
              label="fullname"
              :options="scrumTeam"
              :reduce="({ id }) => id"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="mt-4">
      <div class="d-flex justify-content-between">
        <h5 class="font-weight-bold">Development Team List</h5>
      </div>
      <div
        v-if="!employees.length"
        class="text-center"
      >
        <img src="@/assets/icon-files.png" />
      </div>
      <ul
        v-else
        :style="gridStyle"
        class="card-list mt-4 m-0 p-0"
      >
        <b-card
          v-for="(item, p) in employees"
          :key="p"
          class="shadow-sm"
          body-class="p-3"
        >
          <div class="mb-3 d-flex">
            <b-avatar></b-avatar>
            <div class="ml-3">
              <div class="font-weight-bold mb-1">{{ item.fullname }}</div>
              <b-badge
                class="px-3 py-1 mr-2"
                pill
              >
                {{ item.developerRole }} - {{ item.developerLevel }}
              </b-badge>
              <b-badge
                v-if="item.isLeader"
                class="px-3 py-1 leader"
                pill
              >
                Leader
              </b-badge>
            </div>
          </div>
          <b-row>
            <b-col cols="8" class="text-secondary">
              <div>Role</div>
              <b-badge
                v-for="(value, index) in item.roleLevel"
                :key="index"
                class="px-3 py-1 mr-2"
                pill
              >
                {{ value.role.code }} - {{ value.level.name }}
              </b-badge>
            </b-col>
            <b-col cols="4">
              <div class="text-secondary">Man A Day</div>
              <div>{{ item.manADay }}</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <div class="text-secondary">Periode</div>
              <div>{{moment(item.startDate).format('DD MMM YYYY')}} - {{moment(item.endDate).format('DD MMM YYYY')}}</div>
            </b-col>
            <b-col cols="4">
              <div class="text-secondary">Mandays</div>
              <div>{{ item.manDays }}</div>
            </b-col>
          </b-row>
        </b-card>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  components: {},

  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`
      }
    }
  },

  data: () => ({
    productOwner: null,
    scrumMaster: null,
    scrumTeam: [],
    employees: [],
    numberOfColumns: 3,
    leader: null,
    isUpdate: null
  }),

  created() {
    this.fetchScrumTeam()
    if (this.$route.query.id) this.fetchGetDevelopmentTeam()
  },

  methods: {
    async fetchGetDevelopmentTeam() {
      this.isUpdate = true

      const { data } = await api.developmentTeam.get(this.$route.query.id)

      if (data.status !== 404) {
        data.data.projectDevelopmentTeams.map(v => {
          v.roleLevel = []
          v.roleLevel.push({
            label: 0,
            role: {
              code: v.developmentTeamRoles[0].roleName
            },
            level: {
              name: v.developmentTeamRoles[0].levelName
            }
          })

          v.status = null

          v.developmentTeamRoleRequests = v.developmentTeamRoles

          delete v.developmentTeamRoles
        })
        this.productOwner = data.data.poDeveloper
        this.scrumMaster = data.data.smDeveloperId
        this.employees = data.data.projectDevelopmentTeams
      }
    },
    async fetchScrumTeam() {
      const { data } = await api.developmentTeam.scrumTeams()
      this.scrumTeam = data.data
    }
  }
}
</script>

<style lang="scss" scoped>
.absolute-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.badge {
  background: #F6F4F4;
  color: #A4A4A4;

  &.leader {
    background: #FFF3DF;
    color: #F9BF59;
  }
}
</style>
