<template>
    <b-modal
    id="modal-sprint"
    title="Sprint List"
    size="lg"
    hide-footer
    centered
  >
   <div
            v-if="!item.length"
            class="text-center"
          >
            <span>No Data</span>
          </div>
    <b-table
    v-if="item.length"
      class="mt-4"
      striped
      hover
      :items="item"
      :fields="fields"
    >
      <template #cell(sendDate)="{ item }">
        {{ item.sendDate ? moment(item.sendDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(amount)="{ item }">
        {{ formatMoney(item.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { formatMoney } from 'accounting-js'

export default {
  props: {
    item: {
      type: Array,
      default: () => ''
    }
  },

  methods: {
    formatMoney
  },

  data: () => ({
    fields: [
      { key: 'sprintName', label: 'Sprint No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'startDay', label: 'Start Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'sprintStart', label: 'Start Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'endDay', label: 'End Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'sprintEnd', label: 'End Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'holidays', label: 'Holidays', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'totalWorkDay', label: 'Total Work Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'totalHoliday', label: 'Total Holiday', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'duration', label: 'Duration', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remark', label: 'Remark', tdClass: 'text-center', thClass: 'text-center' }
    ]
  })
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
</style>
