<template>
  <div class="view-project">
    <div class="container-view top p-4">
     <div class="mt-4">
      <div class="d-flex justify-content-between">
      <h5 class="mb-4 font-weight-bold">
        Proposal
    </h5>
    <b-button
          class="reset"
          variant="outline-warning"
          @click="openModal()"
        >
          View Proposal History
        </b-button>
    </div>
    </div>
    <b-row>
      <b-col>
        <div class="text-muted">Project Name</div>
        <div class="font-weight-bold">{{ proposalNo ? proposalNo : '-' }}</div>
      </b-col>
      <b-col>
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Last Uploaded File</div>
                <a href="" v-text="fileName" v-on:click.stop.prevent="download()" />
            </div>
          </div>
      </b-col>
    </b-row>
    <b-row>
    <b-col>
      <div class="text-muted">Project Amount</div>
      <div class="font-weight-bold">{{ formatMoney(amount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
    </b-col>
    <b-col>
      <div class="text-muted">Remark</div>
      <div class="font-weight-bold">{{ remarks ? remarks : '-' }}</div>
    </b-col>
    </b-row>
  </div>
   <div class="container-view bottom p-4">
      <h5 class="mb-4 font-weight-bold">
        Contract
    </h5>
    <b-row>
      <b-col>
        <div class="text-muted">Garuda Contract No</div>
        <div class="font-weight-bold">{{ garudaContractNo ? garudaContractNo : '-' }}</div>
      </b-col>
      <b-col>
        <div class="text-muted">Client Contract No</div>
        <div class="font-weight-bold">{{ clientContractNo ? clientContractNo : '-'}}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-muted">Last Uploaded File(Garuda Contract)</div>
        <a href="" v-text="gikFileName" v-on:click.stop.prevent="downloadGikFile()" />
      </b-col>
       <b-col>
        <div class="text-muted">Last Uploaded File(Client Contract)</div>
        <a href="" v-text="clientFileName" v-on:click.stop.prevent="downloadClientFile()" />
      </b-col>
    </b-row>
    <b-row>
     <b-col>
        <div class="text-muted">Other Info</div>
        <div class="font-weight-bold">{{ otherInfo ? otherInfo : '-' }}</div>
      </b-col>
    </b-row>
  </div>
   <modal-history :item="history" />
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import ModalHistory from './ModalHistory.vue'
import { formatMoney } from 'accounting-js'

export default {
  components: {
    ModalHistory
  },
  created() {
    if (this.$route.query.id) this.fetchProposal()
    this.fetchHistory()
  },
  methods: {
    formatMoney,
    openModal() {
      this.$bvModal.show('modal-history')
    },
    async fetchFile() {
      await api.proposal.get(this.fileNameOri)
    },
    async fetchDownload(value) {
      await api.proposal.download(value).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = value
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    },
    async fetchProposal() {
      const data = await api.proposal.get(this.$route.query.id)
      const propData = data.data
      this.clientContractNo = propData.clientContractNo
      this.garudaContractNo = propData.gikContractNo
      this.amount = propData.projectAmount
      this.sendDate = propData.sentDate
      this.proposalNo = propData.documentNo
      this.otherInfo = propData.otherInfo
      this.remarks = propData.remark
      this.proposal_document = propData.proposalUrl
      this.garuda_contract_document = propData.gikContractUrl
      this.client_contract_document = propData.clientContractUrl
      this.fileNameOri = propData.fileNameOri
      this.clientFileNameOri = propData.clientFileNameOri
      this.gikFileNameOri = propData.gikFileNameOri
      this.fileName = propData.fileName
      this.gikFileName = propData.gikFileName
      this.clientFileName = propData.clientFileName
    },
    async fetchHistory() {
      const data = await api.proposal.getHistory(this.$route.query.id)
      this.history = data.data
    },
    onFileChange(e) {
      if (this.proposalNo !== null || this.sendDate !== null || this.amount > 0) {
        this.isProposalNo = true
      }

      if (this.garudaContractNo !== null) {
        this.isGikNo = true
      }

      if (this.clientContractNo !== null) {
        this.isClientNo = true
      }
    },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY')
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    download() {
      this.fetchDownload(this.fileName)
    },
    downloadGikFile() {
      this.fetchDownload(this.fileName)
    },
    downloadClientFile() {
      this.fetchDownload(this.fileName)
    }
  },
  data: () => ({
    isBusy: false,
    history: [],
    amountDisplay: 2000.00,
    proposalUrl: null,
    fileNameOri: null,
    gikFileNameOri: null,
    clientFileNameOri: null,
    gikUrl: null,
    fileName: null,
    gikFileName: null,
    clientFileName: null,
    clientUrl: null,
    isProposalNo: false,
    isGikNo: false,
    isClientNo: false,
    clientContractNo: null,
    garudaContractNo: null,
    amount: 0,
    sendDate: null,
    proposalNo: null,
    otherInfo: null,
    remarks: null,
    file: null,
    isProposal: false,
    overrideFile: null,
    isUpload: false,
    isOverride: false,
    proposal_document_preview: null,
    proposal_document: null,
    garuda_contract_document: null,
    garuda_contract_document_preview: null,
    client_contract_document: null,
    client_contract_document_preview: null,
    onUploadProgress: false,
    onUpload: false,
    fileUploads: [],
    upload: {
      progress: 0,
      variant: 'success'
    }
  })
}
</script>

<style lang="scss" scoped>
.view-project {
  background: #F5F6F9;
}

.container-view {
  background: #fff;
  flex-direction: column;

  &.top {
    height: 25vw;
  }

  &.bottom {
    height: 24vw;
  }
}

</style>
