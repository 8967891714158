<template>
  <div>
    <v-card class="pt-3 px-3 mb-4 header-view">
      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ detail.name }}</h5>
        <b-badge
          class="px-3 py-2 ml-3"
        >
          {{ detail.status }}
        </b-badge>
      </div>
      <div class="text-muted mt-3">
        Created date : 20 Jan 2022 | Last update : 12 Feb 2022
      </div>
      <b-row class="p-3">
        <b-button
          v-if="isCancel"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Cancel')"
        >
          Cancel
        </b-button>
        <b-button
          v-if="isDeal"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Deal')"
        >
          Deal
        </b-button>
        <b-button
          v-if="isOnHold"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Hold')"
        >
          Hold
        </b-button>
        <b-button
          v-if="isContinue"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Continue')"
        >
          Continue
        </b-button>
        <b-button
          v-if="isReOpen"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Re Open')"
        >
          Re-Open
        </b-button>
        <b-button
          v-if="isDone"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Done')"
        >
          Done
        </b-button>
        <b-button
          v-if="isClose"
          class="reset mr-3 mt-3"
          variant="outline-warning"
          @click="openModal('Close')"
        >
          Close
        </b-button>
      </b-row>
      <hr class="mx-3 mt-4 mb-2">
      <v-tabs
        v-model="tab"
        centered
      >
        <v-tab href="#tab-1">
          Project Detail
        </v-tab>

        <v-tab href="#tab-2">
          Proposal
        </v-tab>

        <v-tab href="#tab-3">
          Payment Term
        </v-tab>

        <v-tab href="#tab-4">
          Development Info
        </v-tab>

        <v-tab href="#tab-5">
          Development Team
        </v-tab>

        <v-tab href="#tab-6">
          File
        </v-tab>

        <v-tab href="#tab-7">
          Cost
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="i in 7"
        :key="i"
        :value="'tab-' + i"
      >
        <v-card>
          <project-detail v-if="i === 1" />
          <proposal v-if="i === 2" />
          <payment-term class="p-4" v-if="i === 3" />
          <development-info v-if="i === 4" />
          <development-team class="p-4" v-if="i === 5" />
          <files class="p-4" v-if="i === 6" />
          <cost class="p-4" v-if="i === 7"/>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <modal-action
      :text="text"
      :project-name="detail.name"
      @submit="updateStatus"
    />
    <modal-deal @submit="updateStatus" />
  </div>
</template>

<script>
import api from '@/api'
import projectDetail from './projectDetail/Index.vue'
import proposal from './proposal/Index.vue'
import paymentTerm from './paymentTerm/Index.vue'
import developmentTeam from './developmentTeam/Index.vue'
import files from './files/Index.vue'
import developmentInfo from './developmentInfo/Index.vue'
import modalAction from './modalAction/ModalAction.vue'
import modalDeal from './modalAction/ModalDeal.vue'
import cost from './cost/Index.vue'

export default {
  components: {
    projectDetail,
    proposal,
    paymentTerm,
    developmentTeam,
    files,
    developmentInfo,
    modalAction,
    modalDeal,
    cost
  },
  data: () => ({
    tab: null,
    detail: {
      id: null,
      status: null
    },
    isOnHold: false,
    isDone: false,
    isCancel: false,
    isDeal: false,
    isContinue: false,
    isReOpen: false,
    isClose: false,
    text: ''
  }),
  created() {
    this.fetchDetail()
  },
  methods: {
    nextStep(item) {
      this.tab = 'tab-' + item
    },
    async updateStatus(value) {
      try {
        const form = {
          projectId: this.detail.id,
          currentStatus: this.detail.status,
          action: this.text === 'Re Open' ? 'Reopen' : this.text,
          remark: value.remark,
          spkNo: value.spkNo,
          spkDate: value.spkDate,
          spkUploads: null
        }

        if (form.action === 'Deal') {
          form.spkUploads = {
            fileName: value.spkUploads.name,
            fileType: null,
            fileExtension: value.spkUploads.name.split('.').pop(),
            base64: await this.getBase64FromUrl(value.spkUploads),
            data: null
          }
        }

        await api.status.update(form)

        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success update project status to ${this.text}`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.fetchDetail()
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async fetchDetail() {
      const { data } = await api.projectDetail.get(this.$route.query.id)

      this.detail = data.data
      this.getActiveStatus()
    },
    getActiveStatus() {
      this.isReOpen = this.detail.status === 'On Hold'
      this.isContinue = this.detail.status === 'Canceled'
      this.isOnHold = this.detail.status === 'Prospect' || this.detail.status === 'Proposal Submitted' || this.detail.status === 'Preparation' || this.detail.status === 'In Progress'
      this.isClose = this.detail.status === 'Maintenance' || this.detail.status === 'Done'
      this.isDone = this.detail.status === 'In Progress'
      this.isDeal = this.detail.status === 'Prospect' || this.detail.status === 'Proposal Submitted'
      this.isCancel = this.detail.status === 'Prospect' || this.detail.status === 'Proposal Submitted' || this.detail.status === 'Preparation' || this.detail.status === 'In Progress'
    },
    openModal(value) {
      this.text = value

      if (value !== 'Deal') {
        this.$bvModal.show('modal-action')
      } else {
        this.$bvModal.show('modal-deal')
      }
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}

.header-view {
  border-radius: 12px;
}
</style>
