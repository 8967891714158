<template>
 <div class="card-project">
 <div class="d-flex justify-content-between">
        <h5 class="mb-4 font-weight-bold">
          Files
        </h5>
      </div>
    <b-row v-show="fileForm.length == 0">
      <b-col>
       <div align="center">
       <img src="@/assets/icon-no-files.png"/>
      </div>
      </b-col>
    </b-row>
    <b-table v-show="fileForm.length > 0"
      class="mt-2"
      striped
      hover
      :items="fileForm"
      :fields="fields"
    >
      <template #cell(link)="items">
      <a v-if="items.item.link !== null" href="" v-on:click.stop.prevent="openWindow(items.item.link)"> Link </a>
      <a v-if="items.item.link == null" href="" v-text="items.item.fileName" v-on:click.stop.prevent="download(items.item)" />
      </template>

      <template #cell(no)="{ item, index }">
      {{ index + 1 }}
      </template>
    </b-table>
    </div>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    isBusy: false,
    isEdit: false,

    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'documentName', label: 'File Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'documentDesc', label: 'Description', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'link', label: 'File', tdClass: 'text-center', thClass: 'text-center' }
    ],
    projectId: localStorage.getItem('projectId'),
    projectCode: localStorage.getItem('projectCode'),
    fileForm: [],
    isUpdate: false
  }),

  created() {
    this.fetchDetailFiles()
  },

  methods: {
    openWindow: function(link) {
      window.open(link)
    },
    download(item) {
      this.fetchDownload(item.fileName)
    },
    async fetchDownload(paramFileName) {
      await api.files.download(paramFileName).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = paramFileName
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    },
    async fetchDetailFiles() {
      this.isUpdate = true
      const data = await api.files.get(this.$route.query.id, this.$route.query.code)

      this.fileForm = data.status === 404 ? [] : data.data
      this.fileForm.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.fileForm
    }
  }
}
</script>
